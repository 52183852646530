import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'InvestismentAmarenco',
  viewBox: '0 0 70 74',
  path: (
    <>
      <path
        opacity="0.5"
        d="M14.4246 27.7165C13.2118 25.5105 14.2621 23.6716 16.759 23.6295L66.3496 22.7965C68.8452 22.7547 69.8663 24.5088 68.6181 26.6941L42.983 71.5746C41.7347 73.7599 39.7213 73.7431 38.5093 71.5376L14.4246 27.7165Z"
        fill="#F28C16"
      />
      <path
        d="M18.7741 66.3544H24.9116V63.1566C22.8582 63.2575 20.8012 63.3145 18.7741 63.3301V66.3544ZM26.8434 68.0886H16.8459V61.5976H17.8109C20.4435 61.5976 23.1351 61.5214 25.8186 61.37L26.8434 61.3132V68.0886Z"
        fill="#354774"
      />
      <path
        d="M31.6747 66.3545H37.8149V61.8978C35.8368 62.1923 33.7807 62.4483 31.6747 62.6511V66.3545ZM39.744 68.0887H29.7456V61.0903L30.6135 61.0086C33.3786 60.7554 36.0694 60.4214 38.6112 60.0149L39.744 59.8313V68.0887Z"
        fill="#354774"
      />
      <path
        d="M44.5753 66.3545H50.7157V59.135C48.8094 59.6901 46.7522 60.2086 44.5753 60.6738V66.3545ZM52.6448 68.0888H42.6462V59.2965L43.3942 59.1423C46.281 58.5431 48.9627 57.8576 51.3629 57.1116L52.6448 56.7097V68.0888Z"
        fill="#354774"
      />
      <path
        d="M57.4761 66.3545H63.6137V53.5412C61.6682 54.7973 59.6139 55.8723 57.4761 56.7528V66.3545ZM65.5454 68.0887H55.5471V55.6398L56.1133 55.4095C58.9053 54.3336 61.5392 52.8781 63.9422 51.0831L65.5454 49.8196V68.0887Z"
        fill="#354774"
      />
      <path
        d="M17.8109 58.7946H16.845V57.0603H17.8109C38.3651 57.0603 53.2621 53.1908 63.3586 45.2234L64.0846 44.6536L65.3557 45.9565L64.6294 46.5291C54.1653 54.7819 38.8479 58.7927 17.8071 58.7927"
        fill="#354774"
      />
      <path d="M65.5454 48.9663H63.6137V46.1345H60.4645V44.4004H65.5481L65.5454 48.9663Z" fill="#354774" />
      <path
        d="M41.1909 32.6983C35.529 32.6983 30.9038 28.626 30.7513 23.5692C30.2783 22.8755 28.9521 20.8063 28.8096 19.2565L28.7287 18.4169C28.699 18.0663 28.7559 17.7138 28.894 17.3908C29.0321 17.0678 29.2473 16.7844 29.5203 16.5661C29.8127 16.3268 30.1509 16.1513 30.5137 16.0507C30.8765 15.9501 31.256 15.9266 31.6282 15.9816L31.9859 16.0972L33.2568 16.7937L32.2501 18.2729L31.189 17.6911C31.0619 17.7017 30.941 17.7517 30.843 17.8343C30.7782 17.8852 30.7273 17.9519 30.6947 18.028C30.6622 18.1042 30.6491 18.1875 30.6568 18.2701L30.7351 19.1124C31.0868 20.4636 31.6954 21.7327 32.5269 22.8489L32.6812 23.0618V23.315C32.6812 27.5314 36.494 30.9622 41.1909 30.9622C45.8878 30.9622 49.7034 27.5314 49.7034 23.315V22.9012L50.0618 22.6406C51.025 21.9396 51.488 20.9191 51.6569 19.1124L51.7358 18.2701C51.742 18.1875 51.7283 18.1048 51.6957 18.0288C51.6631 17.9529 51.6128 17.8861 51.549 17.8343C51.4486 17.7498 51.3242 17.6997 51.1939 17.6911L49.8832 18.3719L48.9144 16.8707L50.76 15.9816C51.1318 15.927 51.5107 15.9507 51.873 16.0513C52.2353 16.1519 52.5732 16.3271 52.8652 16.5661C53.1386 16.7841 53.3541 17.0674 53.4924 17.3905C53.6307 17.7135 53.6876 18.0662 53.6578 18.4169L53.5786 19.2593C53.5673 20.0965 53.3885 20.9228 53.0527 21.6883C52.717 22.4538 52.2314 23.1426 51.6252 23.7132C51.3901 28.7031 46.802 32.6965 41.1909 32.6965"
        fill="#354774"
      />
      <path
        d="M52.5067 17.1469H50.5759V15.5567C50.5759 11.4092 46.8202 8.03248 42.2023 8.03248H40.1879C35.5727 8.03248 31.8154 11.4064 31.8154 15.5567V17.1469H29.8863V15.5567C29.8863 10.453 34.506 6.30103 40.1879 6.30103H42.2023C47.8842 6.30103 52.5067 10.453 52.5067 15.5567V17.1469Z"
        fill="#354774"
      />
      <path
        d="M18.7758 55.2094H16.8441V38.791L34.2682 33.0928V29.9363H36.1972V34.3068L18.775 40.005L18.7758 55.2094Z"
        fill="#354774"
      />
      <path d="M56.6308 37.7211L46.1912 34.3068V29.9363H48.122V33.09L57.2908 36.0906L56.6308 37.7211Z" fill="#354774" />
      <path
        d="M41.193 39.4865C38.503 39.5118 35.8935 38.5597 33.8398 36.8035L31.977 35.173L33.3251 33.9315L35.185 35.5593C36.8718 36.9748 38.9947 37.7504 41.187 37.7523C43.3793 37.7541 45.5034 36.982 47.1925 35.5694L49.0752 33.9297L50.4187 35.1757L48.536 36.8136C46.4842 38.5639 43.8795 39.5123 41.1947 39.4865"
        fill="#354774"
      />
      <path d="M28.9895 44.114H27.0577V54.9205H28.9895V44.114Z" fill="#354774" />
      <path d="M55.3281 44.1387H53.3964V49.1111H55.3281V44.1387Z" fill="#354774" />
      <path
        d="M65.5454 42.666H63.6137V40.005L47.0916 34.6012L47.7534 32.9707L65.5407 38.7901L65.5454 42.666Z"
        fill="#354774"
      />
    </>
  ),
})

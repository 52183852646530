import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'PowerAmarenco',
  viewBox: '0 0 63 64',
  path: (
    <>
      <path
        opacity="0.5"
        d="M13.0882 28.788C11.4364 27.0814 11.954 25.1517 14.2386 24.4991L59.6148 11.542C61.8983 10.8901 63.264 12.2332 62.6488 14.5265L50.0155 61.6249C49.4004 63.9182 47.5457 64.3982 45.8948 62.6918L13.0882 28.788Z"
        fill="#F28C16"
      />
      <path
        d="M16.4388 51.6103H12.7828V27.1389H26.6135V28.7705H14.3573V49.9787H16.4397L16.4388 51.6103Z"
        fill="#354774"
      />
      <path d="M26.0832 37.5648H13.5706V35.9332H24.5052V30.9961H26.0815L26.0832 37.5648Z" fill="#354774" />
      <path d="M29.6414 45.2314H28.0652V44.0962H13.5706V42.4646H29.6414V45.2314Z" fill="#354774" />
      <path d="M26.6134 23.072H9.07521V24.701H26.6134V23.072Z" fill="#354774" />
      <path
        d="M4.27235 17.4832C3.54414 17.497 2.85116 17.799 2.34537 18.3228C1.83958 18.8467 1.56247 19.5496 1.57449 20.2776V47.8213C2.32706 47.1798 3.28329 46.827 4.27235 46.826H8.29015V17.4832H4.27235ZM13.5697 55.6763H4.27235C3.11944 55.654 2.02237 55.1759 1.22152 54.3466C0.420675 53.5173 -0.0186572 52.4045 -0.000157035 51.252V20.2776C-0.0188902 19.1248 0.420464 18.0117 1.22131 17.1821C2.02216 16.3525 3.11921 15.874 4.27235 15.8516H9.86374V48.4576H4.27235C3.54813 48.4831 2.86219 48.7885 2.35886 49.3096C1.85553 49.8307 1.57428 50.5268 1.57428 51.2511C1.57428 51.9755 1.85553 52.6716 2.35886 53.1927C2.86219 53.7138 3.54813 54.0192 4.27235 54.0447H13.5697V55.6763Z"
        fill="#354774"
      />
      <path
        d="M39.1105 43.0896C37.7368 43.0856 36.3934 42.6848 35.2422 41.9353C31.9681 39.8194 28.0669 36.4768 28.0669 32.5636V20.6779H29.6405V32.5575C29.6405 35.8906 33.673 38.9924 36.0766 40.5455C36.9778 41.137 38.0324 41.4521 39.1105 41.4521C40.1887 41.4521 41.2433 41.137 42.1445 40.5455C44.5489 38.9916 48.5804 35.8932 48.5804 32.5601V20.6746H50.1567V32.5575C50.1567 36.4707 46.253 39.8134 42.9788 41.9275C41.8283 42.6786 40.4847 43.0801 39.1105 43.0834"
        fill="#354774"
      />
      <path
        d="M26.0841 19.8588H52.1421V16.5188C52.1421 9.07646 46.2988 3.02321 39.1167 3.02321C31.9345 3.02321 26.0883 9.07646 26.0883 16.5188L26.0841 19.8588ZM53.714 21.4904H24.5052V16.5188C24.5052 8.17951 31.0553 1.3916 39.1097 1.3916C47.1641 1.3916 53.7115 8.17951 53.7115 16.5188L53.714 21.4904Z"
        fill="#354774"
      />
      <path d="M55.1183 19.8579H22.7123V21.4895H55.1183V19.8579Z" fill="#354774" />
      <path d="M42.5392 12.1411H35.6835V2.20728H37.258V10.5095H40.9631V2.20728H42.5392V12.1411Z" fill="#354774" />
      <path
        d="M63 58.7909H61.423C61.4494 56.9941 60.8992 55.236 59.853 53.7745C58.8068 52.313 57.3198 51.2251 55.6098 50.6701L48.4113 48.4091C47.421 48.0952 46.5112 47.5687 45.7459 46.8665C44.9794 46.1595 44.3681 45.3012 43.9507 44.3459C43.5332 43.3905 43.3188 42.3589 43.3209 41.3164V40.2581H44.897V41.3164C44.8942 42.1231 45.0586 42.9218 45.3796 43.662C45.7007 44.4021 46.1714 45.0679 46.7624 45.6173C47.3647 46.1783 48.0844 46.5985 48.869 46.8475L56.0667 49.1083C58.1056 49.7701 59.8788 51.0673 61.1265 52.8097C62.3743 54.5522 63.0308 56.6484 63 58.7909Z"
        fill="#354774"
      />
      <path
        d="M16.4051 58.7909H14.8323C14.8016 56.6485 15.4582 54.5526 16.7057 52.8102C17.9533 51.0677 19.7262 49.7704 21.7649 49.1083L28.9963 46.8389C29.7513 46.6024 30.4458 46.2046 31.0319 45.6734C31.6348 45.1232 32.1157 44.4531 32.4438 43.706C32.7719 42.9589 32.94 42.1514 32.9372 41.3355V40.2581H34.5108V41.3355C34.5147 42.3826 34.2992 43.419 33.8784 44.3779C33.4576 45.3369 32.8407 46.1972 32.0674 46.9036C31.3146 47.5858 30.4226 48.0965 29.453 48.4005L22.2219 50.6701C20.512 51.2253 19.025 52.3133 17.9789 53.7747C16.9327 55.2361 16.3823 56.9941 16.4084 58.7909"
        fill="#354774"
      />
      <path
        d="M38.9153 49.8448C34.7784 49.8448 31.5371 47.817 31.5371 45.2314H33.1133C33.1133 46.642 35.4944 48.2132 38.9153 48.2132C42.3362 48.2132 44.7165 46.642 44.7165 45.2314H46.2935C46.2935 47.817 43.0522 49.8448 38.9153 49.8448Z"
        fill="#354774"
      />
      <path
        d="M38.9153 54.0532C32.7238 54.0532 27.8736 51.1 27.8736 47.3284H29.4505C29.4505 50.0909 33.7852 52.4216 38.9153 52.4216C44.0454 52.4216 48.3801 50.0909 48.3801 47.3284H49.9545C49.9545 51.1 45.106 54.0532 38.9153 54.0532Z"
        fill="#354774"
      />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'InstallationAmarenco',
  viewBox: '0 0 87 74',
  path: (
    <>
      <path
        opacity="0.5"
        d="M73.8766 32.1471C76.3008 32.8408 76.8501 34.8951 75.0974 36.7128L40.285 72.8103C38.533 74.6268 36.5648 74.1158 35.9121 71.6746L22.5056 21.5371C21.8529 19.0959 23.3021 17.6661 25.7254 18.3601L73.8766 32.1471Z"
        fill="#F28C16"
      />
      <path
        d="M39.8688 67.0277H10.7532V65.2908H37.7756V35.9974H6.94515V43.1434H4.85191V34.2605H39.8697L39.8688 67.0277Z"
        fill="#354774"
      />
      <path
        d="M64.1452 67.0277H38.8232V65.2908H62.0519V8.66134H57.7983V6.92456H64.1461L64.1452 67.0277Z"
        fill="#354774"
      />
      <path d="M31.2215 35.5527H29.1283V6.92358H37.4079V8.66047H31.2215V35.5527Z" fill="#354774" />
      <path d="M87 67.0276H63.0957V65.2907H84.9086V20.9958H63.0957V19.259H86.9982L87 67.0276Z" fill="#354774" />
      <path
        d="M5.89954 46.5465C4.44505 46.5465 2.09323 52.03 2.09323 54.695C2.09323 56.4411 3.80075 57.8536 5.89954 57.8536C7.99834 57.8536 9.70585 56.4365 9.70585 54.695C9.70585 52.03 7.35495 46.5465 5.89954 46.5465ZM5.89954 59.5904C2.64596 59.5904 0 57.3941 0 54.695C0 52.8065 1.96774 44.8096 5.89954 44.8096C9.83134 44.8096 11.7991 52.8047 11.7991 54.695C11.7991 57.3941 9.15405 59.5904 5.89954 59.5904Z"
        fill="#354774"
      />
      <path d="M6.94701 54.7463H4.85371V63.5097H6.94701V54.7463Z" fill="#354774" />
      <path
        d="M4.71453 65.2935H6.98834L7.3054 63.5567H4.4543L4.71453 65.2935ZM8.79024 67.0304H2.86318L2.08406 61.8198H9.73964L8.79024 67.0304Z"
        fill="#354774"
      />
      <path
        d="M17.8206 65.2934H26.9095V54.8079H17.8206V65.2934ZM29.0019 67.0303H15.7253V53.071H29.0019V67.0303Z"
        fill="#354774"
      />
      <path
        d="M41.2702 9.81567L41.4534 9.85325C48.1439 11.1904 54.8903 7.76357 56.4988 2.21568L56.5455 2.06222L56.3623 2.02452C49.6718 0.692003 42.9254 4.11425 41.3169 9.6649L41.2702 9.81567ZM44.3761 11.8806C43.2311 11.8805 42.0889 11.7676 40.9659 11.5434L38.746 11.0986L39.2814 9.2606C41.1621 2.78179 49.0384 -1.21588 56.8525 0.338119L59.0724 0.78482L58.5381 2.62278C56.9324 8.15321 50.9511 11.8806 44.3761 11.8806Z"
        fill="#354774"
      />
      <path
        d="M38.2584 13.0956L36.804 11.8476C37.775 11.074 38.7845 10.3501 39.8285 9.6788C40.6861 9.12622 41.5751 8.62423 42.491 8.17536C43.0253 7.89967 43.6888 7.56237 44.6236 7.06152L45.7574 8.51998C44.807 9.02909 44.1343 9.37648 43.5898 9.65401C42.7379 10.0696 41.9116 10.5361 41.1153 11.0509C40.1285 11.6835 39.1743 12.3659 38.2566 13.0956"
        fill="#354774"
      />
      <path d="M15.0316 39.8076H11.2061V41.5445H15.0316V39.8076Z" fill="#354774" />
      <path d="M24.2736 39.8076H20.4508V41.5445H24.2736V39.8076Z" fill="#354774" />
      <path d="M33.5138 39.8076H29.691V41.5445H33.5138V39.8076Z" fill="#354774" />
      <path d="M15.0316 46.4397H11.2061V48.1766H15.0316V46.4397Z" fill="#354774" />
      <path d="M24.2736 46.4397H20.4508V48.1766H24.2736V46.4397Z" fill="#354774" />
      <path d="M33.5138 46.4397H29.691V48.1766H33.5138V46.4397Z" fill="#354774" />
      <path d="M71.8172 24.7979H67.9944V26.5347H71.8172V24.7979Z" fill="#354774" />
      <path d="M81.0601 24.7979H77.2346V26.5347H81.0601V24.7979Z" fill="#354774" />
      <path d="M71.8172 31.4299H67.9944V33.1668H71.8172V31.4299Z" fill="#354774" />
      <path d="M81.0601 31.4299H77.2346V33.1668H81.0601V31.4299Z" fill="#354774" />
      <path d="M71.8172 38.0605H67.9944V39.7974H71.8172V38.0605Z" fill="#354774" />
      <path d="M81.0601 38.0605H77.2346V39.7974H81.0601V38.0605Z" fill="#354774" />
      <path d="M71.8172 44.6926H67.9944V46.4295H71.8172V44.6926Z" fill="#354774" />
      <path d="M81.0601 44.6926H77.2346V46.4295H81.0601V44.6926Z" fill="#354774" />
      <path d="M71.8172 51.3223H67.9944V53.0591H71.8172V51.3223Z" fill="#354774" />
      <path d="M81.0601 51.3223H77.2346V53.0591H81.0601V51.3223Z" fill="#354774" />
      <path d="M71.8172 57.9546H67.9944V59.6887H71.8172V57.9546Z" fill="#354774" />
      <path d="M81.0601 57.9546H77.2346V59.6887H81.0601V57.9546Z" fill="#354774" />
      <path
        d="M36.553 20.031H56.716V17.0562H36.553V20.031ZM58.8137 21.7688H34.4595V15.3193H58.8137V21.7688Z"
        fill="#354774"
      />
      <path
        d="M36.553 29.5094H56.716V26.5328H36.553V29.5094ZM58.8137 31.2472H34.4595V24.7959H58.8137V31.2472Z"
        fill="#354774"
      />
      <path d="M58.8137 40.7236H42.3361V38.9867H56.7252V36.012H42.3361V34.2751H58.8137V40.7236Z" fill="#354774" />
      <path d="M58.8137 50.2021H42.3361V48.4652H56.7252V45.4878H42.3361V43.751H58.8137V50.2021Z" fill="#354774" />
      <path d="M58.8137 50.2049H42.3361V48.468H56.7252V45.4905H42.3361V43.7537H58.8137V50.2049Z" fill="#354774" />
      <path d="M58.8137 59.6823H42.3361V57.9455H56.7252V54.9708H42.3361V53.2339H58.8137V59.6823Z" fill="#354774" />
    </>
  ),
})

import { createIcon } from '@chakra-ui/icons'

export default createIcon({
  displayName: 'LogoAmarenco',
  viewBox: '0 0 183 50',
  path: (
    <>
      <mask id="mask0_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
        <path d="M182.564 0.788757H0.770752V49.8764H182.564V0.788757Z" fill="white" />
      </mask>
      <g mask="url(#mask0_205_7918)">
        <path
          d="M50.7048 32.7804V23.4707L44.9497 29.0565L39.1946 23.4707V32.7804H34.4551V12.1297L44.9497 22.4551L55.4443 12.1297V32.7804H50.7048Z"
          fill="fill"
        />
      </g>
      <mask id="mask1_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
        <path d="M182.564 0.788757H0.770752V49.8764H182.564V0.788757Z" fill="white" />
      </mask>
      <g mask="url(#mask1_205_7918)">
        <mask id="mask2_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask2_205_7918)">
          <path
            d="M93.6988 25.5019C94.7144 24.9941 95.3914 24.317 96.0685 23.3014C96.9149 22.1165 97.4227 20.7624 97.4227 19.239C97.4227 17.2078 96.7456 15.5151 95.2222 14.1609C93.868 12.8068 92.1754 12.1297 90.1441 12.1297H79.6495V32.7804H84.389V26.3482H88.79L92.5139 32.7804H97.9305L93.6988 25.5019ZM91.8368 21.1009C91.329 21.6087 90.8212 21.778 90.1441 21.778H84.389V16.8692H90.1441C90.8212 16.8692 91.329 17.0385 91.8368 17.5463C92.3446 18.0541 92.5139 18.5619 92.5139 19.239C92.6832 19.916 92.3446 20.5931 91.8368 21.1009Z"
            fill="fill"
          />
        </g>
        <mask id="mask3_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask3_205_7918)">
          <path
            d="M100.808 32.7804H115.196V28.2102H105.548V24.8248H115.196V20.2546H105.548V16.8692H115.196V12.1297H100.808V32.7804Z"
            fill="fill"
          />
        </g>
        <mask id="mask4_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask4_205_7918)">
          <path
            d="M128.399 25.6711L137.201 32.6111V11.9604H132.461V22.6243L128.399 19.239L119.597 12.1297V32.7804H124.336V22.1165L128.399 25.6711Z"
            fill="fill"
          />
        </g>
        <mask id="mask5_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask5_205_7918)">
          <path
            d="M154.635 26.5175C153.45 27.7024 152.096 28.2102 150.573 28.2102C149.726 28.2102 149.049 28.0409 148.372 27.7024C147.695 27.3638 147.018 27.0253 146.51 26.5175C146.003 26.0097 145.664 25.3326 145.325 24.6555C144.987 23.9785 144.818 23.3014 144.818 22.4551C144.818 21.6087 144.987 20.9316 145.325 20.2546C145.664 19.5775 146.003 18.9004 146.51 18.3926C147.018 17.8848 147.695 17.5463 148.372 17.2078C149.049 16.8692 149.726 16.6999 150.573 16.6999C152.096 16.6999 153.45 17.2078 154.635 18.3926L155.143 18.9004L158.528 15.5151L158.021 15.0073C157.005 13.9917 155.989 13.3146 154.635 12.8068C153.45 12.299 152.096 11.9604 150.573 11.9604C149.219 11.9604 147.695 12.299 146.51 12.8068C145.325 13.3146 144.141 14.1609 143.125 15.0073C142.109 16.0229 141.432 17.0385 140.925 18.3926C140.417 19.5775 140.078 20.9316 140.078 22.4551C140.078 23.8092 140.417 25.1633 140.925 26.5175C141.432 27.7024 142.279 28.8872 143.125 29.7336C144.141 30.5799 145.156 31.4263 146.51 31.9341C147.695 32.4419 149.219 32.7804 150.573 32.7804C151.927 32.7804 153.45 32.4419 154.635 31.9341C155.82 31.4263 157.005 30.5799 158.021 29.7336L158.528 29.2258L155.143 26.0097L154.635 26.5175Z"
            fill="fill"
          />
        </g>
        <mask id="mask6_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask6_205_7918)">
          <path
            d="M67.2931 12.1297L56.4599 32.7804H61.7072L62.7228 30.7492L65.6004 24.9941L66.4467 23.3014L66.616 23.1321L67.2931 21.6087L68.4779 23.8092L71.3555 29.5643L73.0482 32.7804H78.2955L67.2931 12.1297Z"
            fill="fill"
          />
        </g>
        <mask id="mask7_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask7_205_7918)">
          <path
            d="M177.317 15.1765C176.132 13.9917 174.947 13.3146 173.593 12.8068V18.2233C173.762 18.3926 173.932 18.3926 173.932 18.5619C175.117 19.7468 175.624 20.9316 175.624 22.6243C175.624 24.1477 175.117 25.5019 173.932 26.6867C172.747 27.8716 171.393 28.3794 169.869 28.3794C168.346 28.3794 166.992 27.8716 165.807 26.6867C164.622 25.5019 164.114 24.1477 164.114 22.6243C164.114 21.1009 164.622 19.7468 165.807 18.5619C165.976 18.3926 166.145 18.2233 166.315 18.2233V12.8068C164.961 13.3146 163.606 14.1609 162.591 15.1765C160.56 17.2077 159.544 19.7468 159.544 22.455C159.544 25.3326 160.56 27.7023 162.591 29.7336C164.622 31.7648 167.161 32.7804 170.039 32.7804C172.916 32.7804 175.455 31.7648 177.486 29.7336C179.518 27.7023 180.533 25.3326 180.533 22.455C180.533 19.5775 179.348 17.2077 177.317 15.1765Z"
            fill="fill"
          />
        </g>
        <mask id="mask8_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask8_205_7918)">
          <path
            d="M170.039 19.7467C169.192 19.7467 168.346 19.0697 168.346 18.0541V11.7912C168.346 10.9448 169.023 10.0985 170.039 10.0985C170.885 10.0985 171.731 10.7756 171.731 11.7912V18.0541C171.562 19.0697 170.885 19.7467 170.039 19.7467Z"
            fill="fill"
          />
        </g>
        <mask id="mask9_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask9_205_7918)">
          <path d="M26.1609 32.7804V32.6111V32.7804H28.6999H26.1609Z" fill="fill" />
        </g>
        <mask id="mask10_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask10_205_7918)">
          <path d="M23.9605 28.2102L23.4527 27.1945L23.9605 28.2102L24.299 29.0565L23.9605 28.2102Z" fill="fill" />
        </g>
        <mask id="mask11_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask11_205_7918)">
          <path d="M12.6195 13.6531L12.958 12.9761L12.6195 13.6531Z" fill="fill" />
        </g>
        <mask id="mask12_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask12_205_7918)">
          <path d="M20.2365 21.1009L19.7287 20.0853L18.8823 18.3926L19.7287 20.0853L20.2365 21.1009Z" fill="fill" />
        </g>
        <mask id="mask13_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask13_205_7918)">
          <path d="M25.6531 31.7648L25.4838 31.257L25.6531 31.7648Z" fill="fill" />
        </g>
        <mask id="mask14_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask14_205_7918)">
          <path d="M25.3146 30.9184L24.299 29.2257L25.3146 30.9184Z" fill="fill" />
        </g>
        <mask id="mask15_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask15_205_7918)">
          <path d="M20.7444 21.9472L21.5908 23.8092L20.7444 21.9472Z" fill="fill" />
        </g>
        <mask id="mask16_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask16_205_7918)">
          <path d="M12.4503 13.8224L11.6039 15.6844L12.4503 13.8224Z" fill="fill" />
        </g>
        <mask id="mask17_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask17_205_7918)">
          <path d="M4.49461 29.9028L3.479 31.7648L4.49461 29.9028Z" fill="fill" />
        </g>
        <mask id="mask18_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask18_205_7918)">
          <path d="M15.1585 8.40581L14.3122 10.2678L15.1585 8.40581Z" fill="fill" />
        </g>
        <mask id="mask19_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask19_205_7918)">
          <path d="M2.97131 32.7804L3.47912 31.934L2.97131 32.7804H4.49472H2.97131Z" fill="fill" />
        </g>
        <mask id="mask20_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask20_205_7918)">
          <path d="M12.9579 12.976L14.3121 10.2678L12.9579 12.976Z" fill="fill" />
        </g>
        <mask id="mask21_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask21_205_7918)">
          <path d="M9.74202 32.7804H7.203H9.74202V32.6111V32.7804Z" fill="fill" />
        </g>
        <mask id="mask22_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask22_205_7918)">
          <path d="M18.036 2.98921L17.0204 4.85116L18.036 2.98921Z" fill="fill" />
        </g>
        <mask id="mask23_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask23_205_7918)">
          <path d="M18.0361 16.5306L17.3591 17.8848L18.0361 16.5306Z" fill="fill" />
        </g>
        <mask id="mask24_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask24_205_7918)">
          <path d="M17.0205 18.3926L16.8512 18.9004L17.0205 18.3926Z" fill="fill" />
        </g>
        <mask id="mask25_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask25_205_7918)">
          <path d="M11.6038 29.2257L10.5882 30.9184L10.2497 31.7647L10.5882 30.9184L11.6038 29.2257Z" fill="fill" />
        </g>
        <mask id="mask26_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask26_205_7918)">
          <path d="M25.4835 31.2562L25.3146 30.9184L25.4835 31.2562Z" fill="fill" />
        </g>
        <mask id="mask27_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask27_205_7918)">
          <path d="M17.359 17.8848L17.0204 18.3926L17.359 17.8848Z" fill="fill" />
        </g>
        <mask id="mask28_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask28_205_7918)">
          <path
            d="M26.3302 23.8092L26.1609 23.9785V26.1789L26.3302 26.5175H28.5307L28.6999 26.1789V24.4863L28.5307 23.8092H26.3302Z"
            fill="fill"
          />
        </g>
        <mask id="mask29_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask29_205_7918)">
          <path
            d="M15.6663 21.1009L16.8511 18.9004L17.0204 18.3926H15.497L15.3277 18.5619V20.9316L15.497 21.1009H15.6663Z"
            fill="fill"
          />
        </g>
        <mask id="mask30_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask30_205_7918)">
          <path
            d="M25.9917 26.6868L25.8224 26.5175H23.6219L23.4527 26.6868V27.1946L23.9605 28.2102L24.299 29.0565H25.8224L25.9917 28.8872V26.6868Z"
            fill="fill"
          />
        </g>
        <mask id="mask31_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask31_205_7918)">
          <path
            d="M20.7444 18.2233L20.9137 18.3926H23.1142L23.2835 18.2233V15.8536L23.1142 15.6843H20.9137L20.7444 15.8536V18.2233Z"
            fill="fill"
          />
        </g>
        <mask id="mask32_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask32_205_7918)">
          <path
            d="M18.036 12.8068L18.2052 12.976H20.4057L20.575 12.8068V10.6063L20.4057 10.2678H18.2052L18.036 10.6063V12.8068Z"
            fill="fill"
          />
        </g>
        <mask id="mask33_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask33_205_7918)">
          <path
            d="M28.6999 29.395L28.5307 29.2257H26.3302L26.1609 29.395V31.5955L26.3302 31.7647H28.5307L28.6999 31.5955V29.395Z"
            fill="fill"
          />
        </g>
        <mask id="mask34_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask34_205_7918)">
          <path
            d="M15.1585 21.9472V21.2702L14.9893 21.1009H12.7888L12.6195 21.2702V23.6399L12.7888 23.8092H14.3122L15.1585 21.9472Z"
            fill="fill"
          />
        </g>
        <mask id="mask35_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask35_205_7918)">
          <path d="M15.497 21.1009L15.3277 21.2702V21.778L15.6663 21.1009H15.497Z" fill="fill" />
        </g>
        <mask id="mask36_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask36_205_7918)">
          <path
            d="M7.37227 29.2257L7.203 29.395V31.5955L7.37227 31.7647H9.57275L9.74202 31.5955V29.395L9.57275 29.2257H7.37227Z"
            fill="fill"
          />
        </g>
        <mask id="mask37_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask37_205_7918)">
          <path d="M9.74203 20.9316V19.0697L8.89569 21.1009H9.57276L9.74203 20.9316Z" fill="fill" />
        </g>
        <mask id="mask38_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask38_205_7918)">
          <path
            d="M9.74196 21.2702L9.57269 21.1009H8.89562L7.54147 23.8092H9.57269L9.74196 23.6399V21.2702Z"
            fill="fill"
          />
        </g>
        <mask id="mask39_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask39_205_7918)">
          <path
            d="M10.0805 21.1009H12.281L12.4503 20.9316V18.5619L12.281 18.3926H10.2498L9.91125 19.0697V20.9316L10.0805 21.1009Z"
            fill="fill"
          />
        </g>
        <mask id="mask40_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask40_205_7918)">
          <path
            d="M12.281 26.5175L12.4503 26.1789V23.9785L12.281 23.8092H10.0805L9.91125 23.9785V26.1789L10.0805 26.5175H12.281Z"
            fill="fill"
          />
        </g>
        <mask id="mask41_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask41_205_7918)">
          <path
            d="M15.497 18.3926H17.0204L17.3589 17.8848L18.036 16.5307V15.8536L17.6975 15.6843H15.497L15.3277 15.8536V18.2233L15.497 18.3926Z"
            fill="fill"
          />
        </g>
        <mask id="mask42_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask42_205_7918)">
          <path
            d="M23.6219 23.8092L23.4527 23.9785V26.1789L23.6219 26.5175H25.8224L25.9917 26.1789V23.9785L25.8224 23.8092H23.6219Z"
            fill="fill"
          />
        </g>
        <mask id="mask43_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask43_205_7918)">
          <path
            d="M12.6195 15.5151L12.7888 15.6843H14.9893L15.1585 15.5151V13.1453L14.9893 12.9761H12.958L12.6195 13.6531V15.5151Z"
            fill="fill"
          />
        </g>
        <mask id="mask44_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask44_205_7918)">
          <path
            d="M29.0384 29.2257L28.8692 29.395V31.5955L29.0384 31.7647H31.2389L31.4082 31.5955V29.7335L31.2389 29.2257H29.0384Z"
            fill="fill"
          />
        </g>
        <mask id="mask45_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask45_205_7918)">
          <path
            d="M20.7444 10.6063V12.8068L20.9137 12.976H22.9449L21.7601 10.2678H20.9137L20.7444 10.6063Z"
            fill="fill"
          />
        </g>
        <mask id="mask46_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask46_205_7918)">
          <path d="M12.4503 15.5151V13.8224L11.6039 15.6844H12.281L12.4503 15.5151Z" fill="fill" />
        </g>
        <mask id="mask47_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask47_205_7918)">
          <path d="M10.0805 31.934L9.91125 32.1033V32.6111V32.2726L10.2498 31.934H10.0805Z" fill="fill" />
        </g>
        <mask id="mask48_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask48_205_7918)">
          <path d="M31.7467 31.7648H32.593L31.5774 29.9028V31.5955L31.7467 31.7648Z" fill="fill" />
        </g>
        <mask id="mask49_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask49_205_7918)">
          <path d="M4.15619 31.934H3.47912L2.97131 32.7804H4.49472V32.1033L4.15619 31.934Z" fill="fill" />
        </g>
        <mask id="mask50_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask50_205_7918)">
          <path d="M18.036 2.98921L17.0204 4.85116H18.036V2.98921Z" fill="fill" />
        </g>
        <mask id="mask51_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask51_205_7918)">
          <path
            d="M17.6975 12.976L18.036 12.8068V10.6063L17.6975 10.2678H15.497L15.3277 10.6063V12.8068L15.497 12.976H17.6975Z"
            fill="fill"
          />
        </g>
        <mask id="mask52_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask52_205_7918)">
          <path d="M18.8823 4.85116L18.036 2.98921V4.85116H18.8823Z" fill="fill" />
        </g>
        <mask id="mask53_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask53_205_7918)">
          <path
            d="M12.4503 26.6868L12.281 26.5175H10.0805L9.91125 26.6868V28.8872L10.0805 29.0565H11.6039L12.4503 27.3638V26.6868Z"
            fill="fill"
          />
        </g>
        <mask id="mask54_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask54_205_7918)">
          <path d="M22.9448 26.5175L23.2833 27.1946V26.6868L23.1141 26.5175H22.9448Z" fill="fill" />
        </g>
        <mask id="mask55_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask55_205_7918)">
          <path d="M4.49461 31.5955V29.9028L3.479 31.7648H4.15608L4.49461 31.5955Z" fill="fill" />
        </g>
        <mask id="mask56_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask56_205_7918)">
          <path
            d="M23.2834 26.1789V23.9785L23.1142 23.8092H21.5908L22.9449 26.5175H23.1142L23.2834 26.1789Z"
            fill="fill"
          />
        </g>
        <mask id="mask57_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask57_205_7918)">
          <path d="M6.86432 31.934H4.66384L4.49457 32.1033V32.7804H7.20285V32.1033L6.86432 31.934Z" fill="fill" />
        </g>
        <mask id="mask58_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask58_205_7918)">
          <path
            d="M18.8823 18.3926L19.7287 20.0853L20.2365 21.1009H20.4057L20.575 20.9316V18.5619L20.4057 18.3926H18.8823Z"
            fill="fill"
          />
        </g>
        <mask id="mask59_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask59_205_7918)">
          <path
            d="M12.4503 23.6399V21.2702L12.281 21.1009H10.0805L9.91125 21.2702V23.6399L10.0805 23.8092H12.281L12.4503 23.6399Z"
            fill="fill"
          />
        </g>
        <mask id="mask60_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask60_205_7918)">
          <path d="M20.9137 10.2678H21.5908L20.7444 8.40581V10.0985L20.9137 10.2678Z" fill="fill" />
        </g>
        <mask id="mask61_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask61_205_7918)">
          <path
            d="M9.57275 29.0565L9.74202 28.8872V26.6868L9.57275 26.5175H7.37227L7.203 26.6868V28.8872L7.37227 29.0565H9.57275Z"
            fill="fill"
          />
        </g>
        <mask id="mask62_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask62_205_7918)">
          <path d="M18.036 7.3902L18.2052 7.55946H20.2365L19.0516 5.02045H18.036V7.3902Z" fill="fill" />
        </g>
        <mask id="mask63_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask63_205_7918)">
          <path
            d="M18.036 7.89801V10.0985L18.2052 10.2678H20.4057L20.575 10.0985V8.23655L20.4057 7.72874H18.2052L18.036 7.89801Z"
            fill="fill"
          />
        </g>
        <mask id="mask64_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask64_205_7918)">
          <path
            d="M26.1609 21.2702V23.6399L26.3302 23.8092H28.3614L27.1765 21.1009H26.3302L26.1609 21.2702Z"
            fill="fill"
          />
        </g>
        <mask id="mask65_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask65_205_7918)">
          <path
            d="M4.66384 31.7647H6.86432L7.20285 31.5955V29.395L6.86432 29.2257H4.8331L4.49457 29.7335V31.5955L4.66384 31.7647Z"
            fill="fill"
          />
        </g>
        <mask id="mask66_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask66_205_7918)">
          <path
            d="M11.6039 29.2257H10.0805L9.91125 29.395V31.5955L10.0805 31.7647H10.2498L10.5883 30.9184L11.6039 29.2257Z"
            fill="fill"
          />
        </g>
        <mask id="mask67_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask67_205_7918)">
          <path d="M15.1585 10.0985V8.40581L14.3122 10.2678H14.9892L15.1585 10.0985Z" fill="fill" />
        </g>
        <mask id="mask68_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask68_205_7918)">
          <path
            d="M12.6195 18.2233L12.7888 18.3926H14.9893L15.1585 18.2233V15.8536L14.9893 15.6843H12.7888L12.6195 15.8536V18.2233Z"
            fill="fill"
          />
        </g>
        <mask id="mask69_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask69_205_7918)">
          <path
            d="M12.4502 15.8536L12.2809 15.6843H11.6038L10.2497 18.3926H12.2809L12.4502 18.2233V15.8536Z"
            fill="fill"
          />
        </g>
        <mask id="mask70_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask70_205_7918)">
          <path d="M12.6195 26.6868V27.1946L12.958 26.5175H12.7888L12.6195 26.6868Z" fill="fill" />
        </g>
        <mask id="mask71_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask71_205_7918)">
          <path d="M25.8223 31.934H25.653L25.9916 32.6111V32.1033L25.8223 31.934Z" fill="fill" />
        </g>
        <mask id="mask72_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask72_205_7918)">
          <path d="M31.2389 31.934H29.0384L28.8692 32.1033V32.7804H31.4082V32.1033L31.2389 31.934Z" fill="fill" />
        </g>
        <mask id="mask73_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask73_205_7918)">
          <path d="M31.7467 31.934L31.5774 32.1033V32.7804H33.1008L32.593 31.934H31.7467Z" fill="fill" />
        </g>
        <mask id="mask74_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask74_205_7918)">
          <path
            d="M18.036 13.1453V15.5151L18.2052 15.6843H20.4057L20.575 15.5151V13.1453L20.4057 12.9761H18.2052L18.036 13.1453Z"
            fill="fill"
          />
        </g>
        <mask id="mask75_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask75_205_7918)">
          <path
            d="M26.1609 28.8872L26.3302 29.0565H28.5307L28.6999 28.8872V26.6868L28.5307 26.5175H26.3302L26.1609 26.6868V28.8872Z"
            fill="fill"
          />
        </g>
        <mask id="mask76_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask76_205_7918)">
          <path
            d="M20.7444 21.2702V21.9472L21.5908 23.8092H23.1142L23.2835 23.6399V21.2702L23.1142 21.1009H20.9137L20.7444 21.2702Z"
            fill="fill"
          />
        </g>
        <mask id="mask77_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask77_205_7918)">
          <path
            d="M25.8224 21.1009H23.6219L23.4527 21.2702V23.6399L23.6219 23.8092H25.8224L25.9917 23.6399V21.2702L25.8224 21.1009Z"
            fill="fill"
          />
        </g>
        <mask id="mask78_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask78_205_7918)">
          <path
            d="M15.497 15.6843H17.6975L18.036 15.5151V13.1453L17.6975 12.9761H15.497L15.3277 13.1453V15.5151L15.497 15.6843Z"
            fill="fill"
          />
        </g>
        <mask id="mask79_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask79_205_7918)">
          <path
            d="M18.036 16.5307L18.8823 18.3926H20.4057L20.575 18.2233V15.8536L20.4057 15.6843H18.2052L18.036 15.8536V16.5307Z"
            fill="fill"
          />
        </g>
        <mask id="mask80_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask80_205_7918)">
          <path
            d="M28.5307 31.934H26.3302L26.1609 32.1033V32.6111V32.7804H28.6999V32.1033L28.5307 31.934Z"
            fill="fill"
          />
        </g>
        <mask id="mask81_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask81_205_7918)">
          <path
            d="M14.9893 21.1009L15.1585 20.9316V18.5619L14.9893 18.3926H12.7888L12.6195 18.5619V20.9316L12.7888 21.1009H14.9893Z"
            fill="fill"
          />
        </g>
        <mask id="mask82_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask82_205_7918)">
          <path
            d="M25.9917 31.5955V29.395L25.8224 29.2257H24.299L25.3146 30.9184L25.4839 31.2569L25.6532 31.7647H25.8224L25.9917 31.5955Z"
            fill="fill"
          />
        </g>
        <mask id="mask83_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask83_205_7918)">
          <path
            d="M23.4527 15.8536V18.2233L23.6219 18.3926H25.6532L24.4683 15.6843H23.6219L23.4527 15.8536Z"
            fill="fill"
          />
        </g>
        <mask id="mask84_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask84_205_7918)">
          <path
            d="M14.9892 12.976L15.1584 12.8068V10.6063L14.9892 10.2678H14.3121L12.9579 12.976H14.9892Z"
            fill="fill"
          />
        </g>
        <mask id="mask85_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask85_205_7918)">
          <path d="M18.0362 7.3902V5.02045H17.0206L15.6664 7.55946H17.6976L18.0362 7.3902Z" fill="fill" />
        </g>
        <mask id="mask86_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask86_205_7918)">
          <path
            d="M12.7888 23.8092L12.6195 23.9785V26.1789L12.7888 26.5175H12.958L14.3122 23.8092H12.7888Z"
            fill="fill"
          />
        </g>
        <mask id="mask87_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask87_205_7918)">
          <path
            d="M20.9137 18.3926L20.7444 18.5619V20.9316L20.9137 21.1009H23.1142L23.2835 20.9316V18.5619L23.1142 18.3926H20.9137Z"
            fill="fill"
          />
        </g>
        <mask id="mask88_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask88_205_7918)">
          <path
            d="M23.6219 18.3926L23.4527 18.5619V20.9316L23.6219 21.1009H25.8224L25.9917 20.9316V19.0697L25.8224 18.3926H23.6219Z"
            fill="fill"
          />
        </g>
        <mask id="mask89_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask89_205_7918)">
          <path d="M7.20283 26.1789V24.4863L6.18723 26.5175H6.8643L7.20283 26.1789Z" fill="fill" />
        </g>
        <mask id="mask90_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask90_205_7918)">
          <path d="M24.299 15.6844L23.4527 13.8224V15.5151L23.6219 15.6844H24.299Z" fill="fill" />
        </g>
        <mask id="mask91_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask91_205_7918)">
          <path
            d="M7.20297 28.8872V26.6868L6.86444 26.5175H6.18736L4.83322 29.0565H6.86444L7.20297 28.8872Z"
            fill="fill"
          />
        </g>
        <mask id="mask92_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask92_205_7918)">
          <path
            d="M7.37227 26.5175H9.57275L9.74202 26.1789V23.9785L9.57275 23.8092H7.54154L7.203 24.4863V26.1789L7.37227 26.5175Z"
            fill="fill"
          />
        </g>
        <mask id="mask93_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask93_205_7918)">
          <path d="M26.3302 21.1009H27.0073L26.1609 19.0697V20.9316L26.3302 21.1009Z" fill="fill" />
        </g>
        <mask id="mask94_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask94_205_7918)">
          <path
            d="M28.8692 26.6868V28.8872L29.0384 29.0565H31.0697L29.8848 26.5175H29.0384L28.8692 26.6868Z"
            fill="fill"
          />
        </g>
        <mask id="mask95_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask95_205_7918)">
          <path
            d="M15.497 10.2678H17.6975L18.036 10.0985V7.89801L17.6975 7.72874H15.6663L15.3277 8.23655V10.0985L15.497 10.2678Z"
            fill="fill"
          />
        </g>
        <mask id="mask96_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask96_205_7918)">
          <path d="M20.5751 21.778V21.2702L20.4058 21.1009H20.2365L20.5751 21.778Z" fill="fill" />
        </g>
        <mask id="mask97_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask97_205_7918)">
          <path d="M29.0384 26.5175H29.7155L28.8692 24.4863V26.1789L29.0384 26.5175Z" fill="fill" />
        </g>
        <mask id="mask98_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask98_205_7918)">
          <path d="M9.57275 31.934H7.37227L7.203 32.1033V32.7804H9.74202V32.6111V32.1033L9.57275 31.934Z" fill="fill" />
        </g>
        <mask id="mask99_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask99_205_7918)">
          <path
            d="M20.7444 15.5151L20.9137 15.6843H23.1142L23.2835 15.5151V13.6531L23.1142 12.9761H20.9137L20.7444 13.1453V15.5151Z"
            fill="fill"
          />
        </g>
        <mask id="mask100_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask100_205_7918)">
          <path d="M36.4863 38.197H34.4551V47.676H36.4863V38.197Z" fill="fill" />
        </g>
        <mask id="mask101_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask101_205_7918)">
          <path
            d="M44.9496 47.5067H42.9184V43.275C42.9184 42.7672 42.7491 42.4287 42.5799 42.0901C42.4106 41.7516 42.0721 41.7516 41.7335 41.7516C41.2257 41.7516 40.7179 41.9209 40.5486 42.2594C40.3794 42.5979 40.2101 43.275 40.2101 44.1213V47.5067H38.1789V40.3975H39.7023L40.0408 41.2438H40.2101C40.3794 40.9053 40.7179 40.5667 41.0564 40.3975C41.395 40.2282 41.9028 40.0589 42.4106 40.0589C43.2569 40.0589 43.934 40.2282 44.2725 40.736C44.7803 41.2438 44.9496 41.9209 44.9496 42.7672V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask102_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask102_205_7918)">
          <path
            d="M47.9966 47.5067L45.2883 40.3974H47.3195L48.6736 44.4599C48.8429 44.9677 48.8429 45.4755 49.0122 45.9833C49.0122 45.6447 49.1814 45.1369 49.3507 44.4599L50.7048 40.3974H52.7361L50.0278 47.5067H47.9966Z"
            fill="fill"
          />
        </g>
        <mask id="mask103_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask103_205_7918)">
          <path
            d="M56.6292 47.676C55.4444 47.676 54.598 47.3374 53.9209 46.6604C53.2439 45.9833 52.9053 45.1369 52.9053 43.9521C52.9053 42.7672 53.2439 41.7516 53.7517 41.0745C54.4287 40.3975 55.1058 40.0589 56.2907 40.0589C57.3063 40.0589 58.1526 40.3974 58.6604 40.9053C59.1682 41.4131 59.5068 42.2594 59.5068 43.275V44.2906H54.9366C54.9366 44.7984 55.1058 45.3062 55.4444 45.6448C55.7829 45.9833 56.1214 46.1526 56.7985 46.1526C57.3063 46.1526 57.6448 46.1526 57.9834 45.9833C58.3219 45.814 58.8297 45.814 59.1682 45.4755V46.9989C58.8297 47.1682 58.4912 47.3374 58.1526 47.3374C57.6448 47.676 57.137 47.676 56.6292 47.676ZM56.2907 41.5823C55.9522 41.5823 55.6136 41.7516 55.2751 41.9209C55.1058 42.2594 54.9366 42.5979 54.9366 43.1057H57.6448C57.6448 42.5979 57.4756 42.2594 57.3063 41.9209C57.137 41.7516 56.7985 41.5823 56.2907 41.5823Z"
            fill="fill"
          />
        </g>
        <mask id="mask104_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask104_205_7918)">
          <path
            d="M65.9388 45.3062C65.9388 45.9833 65.6003 46.6604 65.0925 46.9989C64.5846 47.3374 63.7383 47.5067 62.7227 47.5067C62.2149 47.5067 61.7071 47.5067 61.3686 47.3374C61.03 47.3374 60.6915 47.1682 60.353 46.9989V45.3062C60.6915 45.4755 61.1993 45.6447 61.5378 45.814C62.0456 45.9833 62.3842 45.9833 62.7227 45.9833C63.3998 45.9833 63.7383 45.814 63.7383 45.3062C63.7383 45.1369 63.7383 44.9677 63.569 44.9677C63.3998 44.7984 63.2305 44.7984 63.0612 44.6291C62.892 44.4599 62.5534 44.2906 62.2149 44.1213C61.7071 43.9521 61.1993 43.6135 61.03 43.4443C60.6915 43.275 60.5222 42.9365 60.5222 42.7672C60.3529 42.4287 60.353 42.2594 60.353 41.7516C60.353 41.0745 60.5222 40.5667 61.03 40.2282C61.5378 39.8896 62.2149 39.7204 63.0612 39.7204C63.9076 39.7204 64.7539 39.8896 65.6003 40.2282L65.0925 41.5823C64.7539 41.413 64.4154 41.2438 64.0768 41.2438C63.7383 41.0745 63.3998 41.0745 63.0612 41.0745C62.5534 41.0745 62.2149 41.2438 62.2149 41.5823C62.2149 41.7516 62.3842 41.9209 62.5534 42.0901C62.7227 42.2594 63.2305 42.4287 63.7383 42.5979C64.2461 42.7672 64.7539 43.1057 64.9232 43.275C65.2617 43.4443 65.431 43.7828 65.431 43.9521C65.7695 44.7984 65.9388 44.9677 65.9388 45.3062Z"
            fill="fill"
          />
        </g>
        <mask id="mask105_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask105_205_7918)">
          <path
            d="M70.1705 46.1525C70.509 46.1525 70.8476 46.1525 71.3554 45.9833V47.5067C70.8476 47.676 70.3398 47.8452 69.4934 47.8452C68.6471 47.8452 68.1393 47.676 67.8007 47.1682C67.4622 46.8296 67.2929 46.1525 67.2929 45.3062V41.9209H66.2773V41.0745L67.2929 40.3974L67.8007 38.874H68.9856V40.3974H71.0168V41.9209H68.9856V45.3062C68.9856 45.6447 68.9856 45.814 69.1549 45.9833C69.832 45.9833 70.0012 46.1525 70.1705 46.1525Z"
            fill="fill"
          />
        </g>
        <mask id="mask106_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask106_205_7918)">
          <path
            d="M76.0949 38.5355C76.0949 37.8584 76.4335 37.5199 77.1105 37.5199C77.7876 37.5199 78.1262 37.8584 78.1262 38.5355C78.1262 38.874 77.9569 39.0433 77.7876 39.2126C77.6184 39.3818 77.2798 39.5511 76.9413 39.5511C76.4335 39.3818 76.0949 39.0433 76.0949 38.5355ZM78.1262 47.5067H76.0949V40.3975H78.1262V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask107_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask107_205_7918)">
          <path
            d="M86.4203 47.5067H84.389V43.275C84.389 42.7672 84.2198 42.4287 84.0505 42.0901C83.8812 41.7516 83.5427 41.7516 83.2042 41.7516C82.6964 41.7516 82.1886 41.9209 82.0193 42.2594C81.85 42.5979 81.6808 43.275 81.6808 44.1213V47.5067H79.6495V40.3975H81.1729L81.5115 41.2438H81.6808C81.85 40.9053 82.1886 40.5667 82.5271 40.3975C82.8656 40.2282 83.3734 40.0589 83.8812 40.0589C84.7276 40.0589 85.4046 40.2282 85.7432 40.736C86.251 41.2438 86.4203 41.9209 86.4203 42.7672V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask108_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask108_205_7918)">
          <path
            d="M93.6987 43.9521V47.5067H91.6675V38.0277H94.3758C95.7299 38.0277 96.5763 38.1969 97.2533 38.7048C97.9304 39.2126 98.0997 39.8896 98.0997 40.9052C98.0997 41.413 97.9304 41.9208 97.5919 42.4286C97.2534 42.9365 96.9148 43.275 96.2377 43.4443C97.5919 45.4755 98.6075 46.9989 98.946 47.5067H96.7455L94.5451 43.9521H93.6987ZM93.6987 42.2594H94.3758C95.0529 42.2594 95.3914 42.0901 95.7299 41.9208C96.0685 41.7516 96.2377 41.413 96.2377 40.9052C96.2377 40.3974 96.0685 40.0589 95.7299 39.8896C95.3914 39.7204 94.8836 39.5511 94.3758 39.5511H93.6987V42.2594Z"
            fill="fill"
          />
        </g>
        <mask id="mask109_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask109_205_7918)">
          <path
            d="M105.717 47.5067H100.3V38.197H105.717V39.7204H102.332V41.7516H105.378V43.4443H102.332V45.814H105.717V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask110_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask110_205_7918)">
          <path
            d="M107.071 46.6603C107.071 46.3218 107.24 45.9833 107.409 45.814C107.579 45.6447 107.917 45.4755 108.256 45.4755C108.594 45.4755 108.933 45.6447 109.102 45.814C109.271 45.9833 109.441 46.3218 109.441 46.6603C109.441 46.9989 109.271 47.3374 109.102 47.5067C108.933 47.6759 108.594 47.8452 108.256 47.8452C107.917 47.8452 107.579 47.6759 107.409 47.5067C107.24 47.1681 107.071 46.9989 107.071 46.6603Z"
            fill="fill"
          />
        </g>
        <mask id="mask111_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask111_205_7918)">
          <path
            d="M114.18 42.2594H117.904V47.1681C117.227 47.3374 116.719 47.5067 116.211 47.5067C115.704 47.5067 115.196 47.6759 114.519 47.6759C113.165 47.6759 111.98 47.3374 111.303 46.4911C110.626 45.6447 110.118 44.4599 110.118 42.9365C110.118 41.413 110.626 40.2282 111.472 39.3818C112.318 38.5355 113.503 38.0277 115.027 38.0277C116.042 38.0277 116.888 38.197 117.735 38.5355L117.058 40.0589C116.381 39.7204 115.704 39.5511 114.857 39.5511C114.011 39.5511 113.334 39.8896 112.826 40.3974C112.318 40.9052 111.98 41.7516 111.98 42.7672C111.98 43.7828 112.149 44.6291 112.657 45.1369C112.995 45.6447 113.672 45.9833 114.519 45.9833C114.857 45.9833 115.365 45.9833 115.704 45.814V43.7828H114.18V42.2594Z"
            fill="fill"
          />
        </g>
        <mask id="mask112_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask112_205_7918)">
          <path
            d="M122.982 47.676C121.797 47.676 120.951 47.3374 120.274 46.6604C119.597 45.9833 119.258 45.1369 119.258 43.9521C119.258 42.7672 119.597 41.7516 120.105 41.0745C120.782 40.3975 121.459 40.0589 122.644 40.0589C123.659 40.0589 124.506 40.3974 125.013 40.9053C125.521 41.4131 125.86 42.2594 125.86 43.275V44.2906H121.289C121.289 44.7984 121.459 45.3062 121.797 45.6448C122.136 45.9833 122.474 46.1526 123.151 46.1526C123.659 46.1526 123.998 46.1526 124.336 45.9833C124.675 45.814 125.183 45.814 125.521 45.4755V46.9989C125.183 47.1682 124.844 47.3374 124.336 47.3374C123.998 47.676 123.49 47.676 122.982 47.676ZM122.644 41.5823C122.305 41.5823 121.967 41.7516 121.628 41.9209C121.459 42.2594 121.289 42.5979 121.289 43.1057H123.998C123.998 42.5979 123.828 42.2594 123.659 41.9209C123.321 41.7516 122.982 41.5823 122.644 41.5823Z"
            fill="fill"
          />
        </g>
        <mask id="mask113_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask113_205_7918)">
          <path
            d="M133.815 47.5067H131.784V43.275C131.784 42.7672 131.615 42.4287 131.445 42.0901C131.276 41.7516 130.938 41.7516 130.599 41.7516C130.091 41.7516 129.584 41.9209 129.414 42.2594C129.245 42.5979 129.076 43.275 129.076 44.1213V47.5067H127.045V40.3975H128.568L128.906 41.2438H129.076C129.245 40.9053 129.584 40.5667 129.922 40.3975C130.261 40.2282 130.768 40.0589 131.276 40.0589C132.123 40.0589 132.8 40.2282 133.138 40.736C133.646 41.2438 133.815 41.9209 133.815 42.7672V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask114_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask114_205_7918)">
          <path
            d="M138.555 47.676C137.37 47.676 136.524 47.3374 135.847 46.6604C135.169 45.9833 134.831 45.1369 134.831 43.9521C134.831 42.7672 135.169 41.7516 135.677 41.0745C136.354 40.3975 137.031 40.0589 138.216 40.0589C139.232 40.0589 140.078 40.3974 140.586 40.9053C141.094 41.4131 141.432 42.2594 141.432 43.275V44.2906H136.862C136.862 44.7984 137.031 45.3062 137.37 45.6448C137.708 45.9833 138.047 46.1526 138.724 46.1526C139.232 46.1526 139.57 46.1526 139.909 45.9833C140.247 45.814 140.755 45.814 141.094 45.4755V46.9989C140.755 47.1682 140.417 47.3374 139.909 47.3374C139.74 47.676 139.232 47.676 138.555 47.676ZM138.386 41.5823C138.047 41.5823 137.708 41.7516 137.37 41.9209C137.201 42.2594 137.031 42.5979 137.031 43.1057H139.74C139.74 42.5979 139.57 42.2594 139.401 41.9209C139.063 41.7516 138.724 41.5823 138.386 41.5823Z"
            fill="fill"
          />
        </g>
        <mask id="mask115_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask115_205_7918)">
          <path
            d="M146.849 40.2281C147.187 40.2281 147.357 40.2281 147.526 40.2281L147.357 42.0901C147.187 42.0901 147.018 42.0901 146.849 42.0901C146.172 42.0901 145.664 42.2594 145.326 42.5979C144.987 42.9364 144.818 43.4442 144.818 43.952V47.6759H142.787V40.5667H144.31L144.648 41.7516C144.818 41.413 145.156 41.0745 145.495 40.736C146.003 40.3974 146.341 40.2281 146.849 40.2281Z"
            fill="fill"
          />
        </g>
        <mask id="mask116_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask116_205_7918)">
          <path
            d="M152.773 47.5067L152.435 46.4911C152.096 46.8296 151.758 47.1682 151.419 47.3374C151.081 47.5067 150.573 47.5067 150.065 47.5067C149.388 47.5067 148.88 47.3374 148.372 46.9989C148.034 46.6604 147.695 45.9833 147.695 45.3062C147.695 44.4599 148.034 43.9521 148.542 43.6135C149.049 43.275 149.896 43.1057 150.911 42.9365H152.096V42.5979C152.096 41.9209 151.758 41.5823 150.911 41.5823C150.404 41.5823 149.726 41.7516 148.88 42.0901L148.203 40.736C149.049 40.2282 149.896 40.0589 150.911 40.0589C151.927 40.0589 152.604 40.2282 153.112 40.736C153.62 41.0745 153.958 41.7516 153.958 42.5979V47.3374H152.773V47.5067ZM152.265 44.1213H151.588C151.081 44.1213 150.573 44.2906 150.234 44.4599C149.896 44.6292 149.726 44.9677 149.726 45.3062C149.726 45.814 150.065 46.1526 150.742 46.1526C151.25 46.1526 151.588 45.9833 151.758 45.814C151.927 45.6448 152.096 45.1369 152.096 44.7984V44.1213H152.265Z"
            fill="fill"
          />
        </g>
        <mask id="mask117_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask117_205_7918)">
          <path
            d="M158.867 46.1525C159.205 46.1525 159.544 46.1525 160.052 45.9833V47.5067C159.544 47.676 159.036 47.8452 158.19 47.8452C157.343 47.8452 156.836 47.676 156.497 47.1682C156.159 46.8296 155.989 46.1525 155.989 45.3062V41.9209H154.974V41.0745L155.989 40.3974L156.497 38.874H157.682V40.3974H159.882V41.9209H157.851V45.3062C157.851 45.6447 157.851 45.814 158.021 45.9833C158.359 45.9833 158.528 46.1525 158.867 46.1525Z"
            fill="fill"
          />
        </g>
        <mask id="mask118_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask118_205_7918)">
          <path
            d="M160.898 38.5355C160.898 37.8584 161.237 37.5199 161.914 37.5199C162.591 37.5199 162.929 37.8584 162.929 38.5355C162.929 38.874 162.76 39.0433 162.591 39.2126C162.422 39.3818 162.083 39.5511 161.744 39.5511C161.406 39.3818 160.898 39.0433 160.898 38.5355ZM163.099 47.5067H161.067V40.3975H163.099V47.5067Z"
            fill="fill"
          />
        </g>
        <mask id="mask119_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask119_205_7918)">
          <path
            d="M171.223 43.9521C171.223 45.1369 170.885 45.9833 170.377 46.6604C169.7 47.3374 168.854 47.676 167.838 47.676C167.161 47.676 166.484 47.5067 165.976 47.1682C165.468 46.8296 164.961 46.4911 164.791 45.814C164.453 45.3062 164.283 44.6291 164.283 43.7828C164.283 42.5979 164.622 41.7516 165.13 41.0745C165.807 40.3975 166.653 40.0589 167.669 40.0589C168.346 40.0589 169.023 40.2282 169.531 40.5667C170.039 40.9053 170.546 41.2438 170.716 41.9209C171.054 42.4287 171.223 43.1057 171.223 43.9521ZM166.145 43.9521C166.145 44.6291 166.315 45.1369 166.484 45.6448C166.653 46.1526 167.161 46.1526 167.669 46.1526C168.177 46.1526 168.515 45.9833 168.854 45.6448C169.023 45.3062 169.192 44.7984 169.192 43.9521C169.192 43.275 169.023 42.7672 168.854 42.4287C168.684 42.0901 168.177 41.9209 167.669 41.9209C167.161 41.9209 166.822 42.0901 166.484 42.4287C166.315 42.5979 166.145 43.275 166.145 43.9521Z"
            fill="fill"
          />
        </g>
        <mask id="mask120_205_7918" maskUnits="userSpaceOnUse" x="0" y="0" width="183" height="50">
          <path d="M182.564 0.788757H0.770782V49.8764H182.564V0.788757Z" fill="white" />
        </mask>
        <g mask="url(#mask120_205_7918)">
          <path
            d="M179.01 47.5067H176.979V43.275C176.979 42.7672 176.809 42.4287 176.64 42.0901C176.471 41.7516 176.132 41.7516 175.794 41.7516C175.286 41.7516 174.778 41.9209 174.609 42.2594C174.44 42.5979 174.27 43.275 174.27 44.1213V47.5067H172.239V40.3975H173.762L174.101 41.2438H174.27C174.44 40.9053 174.778 40.5667 175.117 40.3975C175.455 40.2282 175.963 40.0589 176.471 40.0589C177.317 40.0589 177.994 40.2282 178.333 40.736C178.841 41.2438 179.01 41.9209 179.01 42.7672V47.5067Z"
            fill="fill"
          />
        </g>
      </g>
    </>
  ),
})
